import React from 'react';
import { AccountsRoutes } from '@services/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';
import withQueryParams from '@components/hocs/withQueryParams';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { defferedLoader } from '@services/loaders';

const userRoutes = {
  path: 'users',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      async lazy() {
        const { Index } = await import('@pages/Users/Index');
        return { Component: withDefferedLoaderData(withQueryParams(Index)) };
      },
      loader: defferedLoader(AccountsRoutes.Users.indexRequest),
    },
    {
      path: 'new',
      async lazy() {
        const { New } = await import('@pages/Users/New');
        return { Component: New };
      },
    },
    {
      path: ':id/edit',
      async lazy() {
        const { Edit } = await import('@pages/Users/Edit');
        return { Component: withDefferedLoaderData(Edit) };
      },
      loader: defferedLoader(AccountsRoutes.Users.editRequest),
    },
    {
      path: 'profile',
      async lazy() {
        const { Profile } = await import('@pages/Users/Profile');
        return { Component: Profile };
      },
    },
  ],
};

export default userRoutes;
