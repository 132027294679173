import React from 'react';
import { useQueryParams } from '@hooks';

function withQueryParams(WrappedComponent) {
  return function WithAsyncValueDataWrapper(props) {
    useQueryParams();

    return <WrappedComponent {...props} />;
  };
}

export default withQueryParams;
