import { useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { omitBy } from 'lodash';
import useQueryParamsStore from '@components/shared/Filter/queryParamsStore';
import qs from 'qs';

export default function useQueryParams() {
  const [, setSearchParams] = useSearchParams();
  const resetStore = useQueryParamsStore((state) => state.resetStore);
  const { oauthApplicationId } = useParams();

  useEffect(() => {
    const unsub = useQueryParamsStore.subscribe(
      ({ filter, pagination }) => {
        const cleanParams = omitBy({ filter, ...pagination }, (value) => value == null || value === '');
        const stringifiedParams = qs.stringify(cleanParams, { arrayFormat: 'brackets', encode: true });

        if (stringifiedParams !== window.location.search.slice(1)) {
          setSearchParams(stringifiedParams);
        }
      },
    );
    return () => { resetStore(); unsub(); };
  }, [oauthApplicationId]);
}
