import React from 'react';
import { OauthApplicationsRoutes } from '@services/routes';
import oauthApplicationsRolesRoutes from '@pages/OauthApplicationsRoles/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';
import withQueryParams from '@components/hocs/withQueryParams';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { defferedLoader } from '@services/loaders';

const oauthApplicationsRoutes = {
  path: 'oauth_applications',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      async lazy() {
        const { Index } = await import('@pages/OauthApplications/Index');
        return { Component: withDefferedLoaderData(withQueryParams(Index)) };
      },
      loader: defferedLoader(OauthApplicationsRoutes.indexRequest),
    },
    {
      path: 'new',
      async lazy() {
        const { New } = await import('@pages/OauthApplications/New');
        return { Component: New };
      },
    },
    {
      path: ':id/edit',
      async lazy() {
        const { Edit } = await import('@pages/OauthApplications/Edit');
        return { Component: withDefferedLoaderData(Edit) };
      },
      loader: defferedLoader(OauthApplicationsRoutes.editRequest),
    },
    oauthApplicationsRolesRoutes,
  ],
};

export default oauthApplicationsRoutes;
