import React from 'react';
import { useAsyncValue } from 'react-router-dom';

function withAsyncValueData(WrappedComponent) {
  return function WithAsyncValueDataWrapper(props) {
    const { data } = useAsyncValue();

    return <WrappedComponent data={data} {...props} />;
  };
}

export default withAsyncValueData;
