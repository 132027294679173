import React from 'react';

import { Spinner as TDSSpinner } from '@tripledotstudios/react-core';

export default function Spinner({ isLoading }) {
  return isLoading && (
    <div className="d-flex justify-content-center align-items-center">
      <TDSSpinner />
    </div>
  );
}
