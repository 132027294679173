import React from 'react';
import { useRouteError, useAsyncError } from 'react-router-dom';

import { ErrorInfoPage } from '@tripledotstudios/react-core';

export function ErrorBoundary() {
  const routeError = useRouteError();
  const asyncError = useAsyncError();
  const error = routeError || asyncError;

  const message = error?.data?.message
    || error?.message
    || error?.statusText
    || "We're sorry, but something went wrong.";

  return <ErrorInfoPage text={message} />;
}

export default ErrorBoundary;
