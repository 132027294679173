import React, {
  useContext, createContext, useState, useEffect,
} from 'react';
import { mapValues, values } from 'lodash';
import { prepareInboundData } from '@services/requests';

import { SystemRoutes as Routes } from '@services/routes';

const appDataContext = createContext({});

const presentAppData = (rawData) => {
  if (!rawData) {
    return {};
  }

  const enumsToOptions = (enums) => mapValues(enums, (klassEnum) => (
    values(klassEnum).map(({ value, translation: label }) => ({ value, label }))
  ));

  return {
    rawEnums: rawData.enums,
    enums: mapValues(rawData.enums, (klassEnum) => mapValues(klassEnum, 'value')),
    localizedEnums: mapValues(rawData.enums, (klassEnum) => mapValues(klassEnum, 'translation')),
    environment: rawData.environment,
    enumOptions: enumsToOptions(rawData.enums),
    applications: rawData.applications,
    userRole: rawData.user_role,
    user: prepareInboundData(rawData.user),
  };
};

function ProvideAppData({ children }) {
  const [data, setData] = useState();

  let loadData;
  const processResponse = (response) => setData({ ...presentAppData(response.data), reload: loadData });

  loadData = () => Routes.appDataRequest({}, { skipInboudProcessing: true }).then(processResponse);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <appDataContext.Provider value={data}>
      {data && children}
    </appDataContext.Provider>
  );
}

const useAppData = () => useContext(appDataContext);

export {
  useAppData,
  ProvideAppData,
};
