import qs from 'qs';
import { defer } from 'react-router-dom';

const getParams = (request, params) => {
  const url = new URL(request.url);
  const urlParams = qs.parse(url.search, { ignoreQueryPrefix: true });
  return { ...urlParams, ...params };
};

export const defaultLoader = (requestFunction) => ({ request, params }) => requestFunction(
  getParams(request, params), { signal: request.signal },
);

export const defferedLoader = (requestFunction) => ({ request, params }) => defer({
  data: requestFunction(getParams(request, params), { signal: request.signal, throwOnErrors: true }),
});
