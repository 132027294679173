import React from 'react';
import { ExternalDomainRoutes } from '@services/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';
import withQueryParams from '@components/hocs/withQueryParams';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { defferedLoader } from '@services/loaders';

const externalDomains = {
  path: 'external_domains',
  errorElement: <ErrorBoundary />,
  async lazy() {
    const { Index } = await import('@pages/ExternalDomains/Index');
    return { Component: withDefferedLoaderData(withQueryParams(Index)) };
  },
  loader: defferedLoader(ExternalDomainRoutes.indexRequest),
};

export default externalDomains;
