import { AccountsRoutes } from '@services/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';
import { defferedLoader } from '@services/loaders';

const utilityRoutes = [
  {
    path: '*',
    async lazy() {
      const { ErrorBoundary } = await import('@components/ErrorBoundary');
      return { Component: ErrorBoundary };
    },
  },
  {
    path: 'admin/forbidden',
    async lazy() {
      const { ErrorBoundary } = await import('@components/ErrorBoundary');
      return { Component: ErrorBoundary };
    },
  },
  {
    path: 'admin/sign_in',
    async lazy() {
      const { SignIn } = await import('@components/SignIn/SignIn');
      return { Component: SignIn };
    },
  },
  {
    path: 'admin/otp',
    async lazy() {
      const { Otp } = await import('@components/SignIn/Otp');
      return { Component: Otp };
    },
  },
  {
    path: 'admin/user_password/edit',
    async lazy() {
      const { Edit } = await import('@pages/Users/Password/Edit');
      return { Component: withDefferedLoaderData(Edit) };
    },
    loader: defferedLoader(AccountsRoutes.Users.Password.editRequest),
  },
];

export default utilityRoutes;
