import React from 'react';
import { useLoaderData, Await, useNavigation } from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';
import Spinner from '@components/shared/Spinner';
import withAsyncValueData from '@components/hocs/withAsyncValueData';

export default function withDefferedLoaderData(WrappedComponent) {
  const WrappedWithAsyncValues = withAsyncValueData(WrappedComponent);

  return function WithLoaderDataWrapper(props) {
    const data = useLoaderData();
    const { state } = useNavigation();
    const dataLoading = state === 'loading';

    return (
      <React.Suspense
        fallback={<Spinner isLoading />}
      >
        <Await
          resolve={data.data}
          errorElement={<ErrorBoundary />}
        >
          <WrappedWithAsyncValues loading={dataLoading} {...props} />
        </Await>
      </React.Suspense>
    );
  };
}
