import { classic, customizeTheme } from '@tripledotstudios/react-core';

export default Object.freeze(
  customizeTheme(
    classic,
    {
      components: {
        sidebar: {
          background: '#3f3054',
          title: classic.variants.light,
          separator: `${classic.variants.light}70`,
          colorfulHover: false,
          minimizeButtonTop: '1.5em',
          activeSectionOpacity: 0.2,
        },
      },
    },
  ),
);
