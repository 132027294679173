import React from 'react';

import {
  ProvideFlashMessages,
  ConfirmProvider,
  getThemeName,
} from '@tripledotstudios/react-core';
import { ThemeProvider } from 'styled-components';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ProvideAppData } from '@hooks';

import classicTheme from '@root/themes/classic';
import { AccountsRoutes } from '@services/routes';
import oauthApplicationsRoutes from '@pages/OauthApplications/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';

import externalDomains from '@pages/ExternalDomains/routes';
import activityLogs from '@pages/ActivityLogs/routes';
import applicationsRoutes from '@pages/Applications/routes';
import userRoutes from '@pages/Users/routes';
import usersGroupsRoutes from '@pages/UsersGroups/routes';
import utilityRoutes from '@components/utilityRoutes';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { defferedLoader } from '@services/loaders';

const router = createBrowserRouter([
  {
    path: '/',
    async lazy() {
      const { Auth } = await import('@components/Auth');
      return { Component: Auth };
    },
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'admin',
        async lazy() {
          const { Layout } = await import('@components/Layout');
          return { Component: Layout };
        },
        children: [
          {
            index: true,
            async lazy() {
              const { Home } = await import('@components/Home');
              return { Component: withDefferedLoaderData(Home) };
            },
            loader: defferedLoader(AccountsRoutes.Users.availableApplicationsRequest),
          },
          userRoutes,
          usersGroupsRoutes,
          externalDomains,
          oauthApplicationsRoutes,
          activityLogs,
          applicationsRoutes,
        ],
      },
    ],
  },
  ...utilityRoutes,
]);

const THEMES_MAPPING = {
  classic: classicTheme,
};

export default function Application() {
  const theme = THEMES_MAPPING[getThemeName()];

  return (
    <ProvideFlashMessages>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <ProvideAppData>
            <RouterProvider router={router} future={{ v7_startTransition: true }} />
          </ProvideAppData>
        </ConfirmProvider>
      </ThemeProvider>
    </ProvideFlashMessages>
  );
}
