import { isArray, isPlainObject } from 'lodash';

export default function processRecursively(data, fn, excludeFn = () => {}) {
  if (isPlainObject(data)) {
    const obj = {};
    Object.keys(data).forEach((key) => {
      const resultKey = excludeFn(key) ? key : fn(key);

      obj[resultKey] = processRecursively(data[key], fn, excludeFn);
    });
    return obj;
  }

  if (isArray(data)) {
    return data.map((i) => processRecursively(i, fn, excludeFn));
  }

  return data;
}
