import { generatePath, matchPath } from 'react-router-dom';
import { omit } from 'lodash';

import {
  get, post, put, destroy, patch,
} from '@services/requests';

// Do not send parameters from url as payload
const cleanUpParams = (path, data) => omit(data, [...path.matchAll(/:(\w+)/g)].map((obj) => obj[1]));
const formDataOptions = { headers: { 'Content-Type': 'multipart/form-data' } };

const actions = {
  get: (path) => (data, options) => get(generatePath(path, data), { ...options, params: cleanUpParams(path, data) }),
  post: (path) => (data, options) => post(generatePath(path, data), cleanUpParams(path, data), options),
  upload: (path) => (formData, params) => post(generatePath(path, params), formData, formDataOptions),
  put: (path) => (data, options) => put(generatePath(path, data), cleanUpParams(path, data), options),
  patch: (path) => (data, options) => patch(generatePath(path, data), cleanUpParams(path, data), options),
  delete: (path) => (data) => destroy(generatePath(path, data), cleanUpParams(path, data)),
};

const addAction = (rootPath, action, actionPath = null, requestType = 'get', apiRootPath = null) => {
  const buildPath = (path) => (actionPath ? `${path}/${actionPath}` : path);
  const path = buildPath(rootPath);
  const apiPath = buildPath(apiRootPath === null ? `/api${rootPath}` : apiRootPath);

  return {
    [`${action}RawPath`]: path,
    [`${action}Path`]: (data) => generatePath(path, data),
    [`${action}Request`]: actions[requestType](apiPath),
    [`${action}MatchPath`]: (currentPath) => matchPath(currentPath, { path }),
  };
};

const resources = (baseUrl, entityName, options = {}) => {
  const rootPath = `${baseUrl}/${entityName}`;
  const addActionIfAllowed = (action, actionPath = null, requestType = 'get') => (
    options.except && options.except.includes(action)
      ? {}
      : addAction(rootPath, action, actionPath, requestType, options.apiRootPath)
  );

  return {
    ...addActionIfAllowed('index'),
    ...addActionIfAllowed('show', ':id'),
    ...addActionIfAllowed('new', 'new'),
    ...addActionIfAllowed('edit', ':id/edit'),
    ...addActionIfAllowed('create', null, 'post'),
    ...addActionIfAllowed('update', ':id', 'put'),
    ...addActionIfAllowed('delete', ':id', 'delete'),
    ...options.overrides ? options.overrides(rootPath) : {},
  };
};

const BaseAppRoute = '/admin';

const UserRoutes = {
  ...addAction('/auth', 'signOut', 'sign_out', 'post', '/auth'),
};

const AccountsRoutes = {
  Users: {
    ...resources(BaseAppRoute, 'users', {
      except: ['delete'],
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'terminateAllSessions', ':id/terminate_all_sessions', 'post'),
        ...addAction(rootPath, 'resetPassword', ':id/reset_password', 'post'),
        ...addAction(rootPath, 'resetOtp', ':id/reset_otp', 'post'),
        ...addAction(rootPath, 'availableApplications', 'available_applications', 'get'),
        ...addAction(rootPath, 'profile', 'profile', 'get'),
        ...addAction(rootPath, 'generatePersonalToken', ':id/generate_personal_token', 'put'),
      }),
    }),
    Password: {
      ...addAction('/admin/user_password', 'edit', 'edit', 'get'),
      ...addAction('/admin/user_password', 'update', null, 'put'),
    },
  },
  UsersGroups: {
    ...resources(BaseAppRoute, 'users_groups'),
  },
};

const OauthApplicationsRoutes = resources(BaseAppRoute, 'oauth_applications', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'synchronizePermissionsSchema', ':id/synchronize_permissions_schema', 'post'),
  }),
});

const OauthApplicationsRolesRoutes = resources(BaseAppRoute, 'oauth_applications/:oauthApplicationId/roles');
const RolesRoutes = { ...addAction('/admin/roles', 'rolesOptions', 'frontend_options', 'get') };
const ExternalDomainRoutes = resources(BaseAppRoute, 'external_domains');
const ActivityLogRoutes = resources('/admin', 'activity_logs');

const SystemRoutes = {
  ...addAction('/admin', 'appData', 'app_data', 'get'),
};

const SessionRoutes = {
  ...addAction('/admin/sessions', 'login', 'login', 'post'),
  ...addAction('/admin/sessions', 'loginWithOtp', 'login_with_otp', 'post'),
};

const ApplicationRoutes = resources('/admin', 'applications');

export {
  UserRoutes,
  AccountsRoutes,
  SystemRoutes,
  OauthApplicationsRoutes,
  OauthApplicationsRolesRoutes,
  RolesRoutes,
  ExternalDomainRoutes,
  SessionRoutes,
  ActivityLogRoutes,
  ApplicationRoutes,
};
