import React from 'react';

import { ApplicationRoutes } from '@services/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';
import withQueryParams from '@components/hocs/withQueryParams';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { defferedLoader } from '@services/loaders';

const applicationsRoutes = {
  path: 'applications',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      async lazy() {
        const { Index } = await import('@pages/Applications/Index');
        return { Component: withDefferedLoaderData(withQueryParams(Index)) };
      },
      loader: defferedLoader(ApplicationRoutes.indexRequest),
    },
    {
      path: 'new',
      async lazy() {
        const { New } = await import('@pages/Applications/New');
        return { Component: New };
      },
    },
    {
      path: ':id/edit',
      async lazy() {
        const { Edit } = await import('@pages/Applications/Edit');
        return { Component: withDefferedLoaderData(Edit) };
      },
      loader: defferedLoader(ApplicationRoutes.editRequest),
    },
  ],
};

export default applicationsRoutes;
