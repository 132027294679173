/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import qs from 'qs';

export const INITIAL_PAGE_SIZE = 10;
export const INITIAL_PAGE = 1;
const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const initialPaginationState = {
  per: queryParams.per ? parseInt(queryParams.per, 10) : INITIAL_PAGE_SIZE,
  page: queryParams.page ? parseInt(queryParams.page, 10) : INITIAL_PAGE,
};
const initialFilterState = queryParams.filter || {};

const useQueryParamsStore = create(
  immer((set, get) => ({
    filter: initialFilterState,
    pagination: initialPaginationState,
    setFilter: (key, value) => set((state) => {
      if (value === undefined) {
        delete state.filter[key];
      } else {
        state.filter[key] = value;
      }
      state.pagination.page = INITIAL_PAGE;
    }),
    setPagination: (key, value) => set((state) => {
      state.pagination[key] = value;
    }),
    resetFilters: () => set((state) => {
      state.filter = {};
      state.pagination.page = INITIAL_PAGE;
    }),
    resetStore: () => set((state) => {
      state.filter = {};
      state.pagination = {
        per: INITIAL_PAGE_SIZE,
        page: INITIAL_PAGE,
      };
    }),
    getFiltersCount: () => {
      const { filter } = get();
      return Object.keys(filter).length;
    },
  })),
);

export default useQueryParamsStore;
