import { camelCase } from 'lodash';

import underscore from 'snakecase-keys';
import qs from 'qs';
import axios from './axios';
import processRecursively from './processRecursively';

const baseHost = '';

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.paramsSerializer = {
    serialize: (params) => qs.stringify(underscore(params), {
      arrayFormat: 'brackets',
      encode: true,
    }),
  };

  return config;
});

function fromCamelCaseToSnakeCase(data) {
  return data.split(/(?=[A-Z])/).join('_').toLowerCase();
}

function withDefaultData(options) {
  return { ...options, data: options.data || {} };
}

function handleUnauthorized(reason) {
  if (reason !== 'session_expired') return;

  const queryString = [window.location.search.substring(1), 'session_expired=true'].join('&');

  window.location = `/admin/sign_in?${queryString}`;
}

function handleError(error, throwOnErrors = false) {
  const { response } = error;
  const { status, data: { reason } } = response;

  if (status === 401) handleUnauthorized(reason);

  if (throwOnErrors) throw error;
  return response;
}

export function prepareInboundData(data) {
  return processRecursively(data, camelCase, (key) => key.match(/^_/));
}

function processInbound(request, options = { throwOnErrors: false }) {
  return request.catch((error) => handleError(error, options.throwOnErrors)).then((response) => (
    {
      data: options.skipInboudProcessing ? response.data : prepareInboundData(response.data),
      status: response.status,
    }
  ));
}

function prepareOutboundData(data) {
  return processRecursively(data, fromCamelCaseToSnakeCase, (key) => key.match(/^_/));
}

export function get(url, options = {}) {
  return processInbound(axios.get(
    `${baseHost}${url}`,
    prepareOutboundData(withDefaultData(options)),
  ), options);
}

export function patch(url, data, options = {}) {
  return processInbound(axios.patch(
    `${baseHost}${url}`,
    prepareOutboundData(data),
    withDefaultData(options),
  ));
}

export function put(url, data, options = {}) {
  return processInbound(axios.put(`${baseHost}${url}`, prepareOutboundData(data), withDefaultData(options)));
}

export function post(url, data, options = {}) {
  return processInbound(axios.post(`${baseHost}${url}`, prepareOutboundData(data), withDefaultData(options)));
}

export function destroy(url, options = {}) {
  return processInbound(axios.delete(`${baseHost}${url}`, options));
}

export function fetchCollection(url, params = {}, options = {}) {
  return get(url, { ...options, params });
}

export function newResource(baseUrl, options = {}) {
  return get(`${baseUrl}/new`, options);
}

export function editResource(baseUrl, data, options = {}) {
  return get(`${baseUrl}/${data.id}/edit`, options);
}

export function getResource(baseUrl, data, options = {}) {
  return get(`${baseUrl}/${data.id}`, options);
}

export function createResource(url, data, options = {}) {
  return post(url, data, options);
}

export function updateResource(baseUrl, data, options = {}) {
  return put(`${baseUrl}/${data.id}`, data, options);
}

export function deleteResource(baseUrl, data, options = {}) {
  return destroy(`${baseUrl}/${data.id}`, options);
}
