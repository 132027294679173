import React from 'react';
import { ActivityLogRoutes } from '@services/routes';
import withDefferedLoaderData from '@components/hocs/withDefferedLoaderData';
import withQueryParams from '@components/hocs/withQueryParams';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { defferedLoader } from '@services/loaders';

const activityLogs = {
  path: 'activity_logs',
  errorElement: <ErrorBoundary />,
  async lazy() {
    const { Index } = await import('@pages/ActivityLogs/Index');
    return { Component: withDefferedLoaderData(withQueryParams(Index)) };
  },
  loader: defferedLoader(ActivityLogRoutes.indexRequest),
};

export default activityLogs;
